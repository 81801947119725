<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  rating: {
    type: Number,
    default: 0
  }
});

const stars = computed(() => {
  const fullStars = Math.floor(props.rating);
  const halfStar = props.rating % 1 >= 0.5 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStar;

  const starIcons = [];

  for (let i = 0; i < fullStars; i++) {
    starIcons.push({ class: 'text-yellow-500', name: 'ri:star-fill' });
  }

  if (halfStar) {
    starIcons.push({ class: 'text-yellow-500', name: 'ri:star-half-fill' });
  }

  for (let i = 0; i < emptyStars; i++) {
    starIcons.push({ class: 'text-yellow-500', name: 'ri:star-line' });
  }

  return starIcons;
});
</script>

<template>
  <div class="rating">
    <Icon v-for="(icon, index) in stars" :key="index" :class="icon.class" size="20" :name="icon.name" />
  </div>
</template>

<style scoped lang="scss">
.rating {
  display: flex;
}
</style>